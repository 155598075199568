import { useState } from "react";
import React, { createContext, useContext, useEffect } from "react";
import { Provider } from "react-redux";
import { productItemHome } from "../../pages/home";
import { productData } from "../../pages/products/mockData";
import { store } from "../store";
import Banner from "./banner/Banner";
import BestSeller from "./best-seller/BestSeller";
import Brands from "./brands/Brands";
import CategoriesItems from "./categories-items/CategoriesItems";
import CostInfo from "./cost-info/CostInfo";
import FeaturedProducts from "./featured-products/FeaturedProducts";
import Network from "./network/Network";
import ProductsCategory from "./products-category/ProductsCategory";
import ProductItem from "./products/product/ProductItem";
import Products from "./products/Products";
import ServiceList from "./service-list/ServiceList";
import TodayDeals from "./today-deals/TodayDeals";
import TrendingProducts from "./trending-products/TrendingProducts";
import AddingShow from "./network/AddingShow";
import Promotion from "./promtion/Promotion";
import Brand from "./brand/Brand";
import UnderProduct from "./underProduct/UnderProduct";
import ProductVideosWrapp from "./banner/productsVideosWrapper/ProductVideosWrapp";
function Home() {
  const productData = useContext(productItemHome)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Banner />
      {/* <ServiceList /> */}
      <ProductsCategory />
      <ProductVideosWrapp />
      <Products productData={productData} />
      {/* <UnderProduct /> */}
      {/* <FeaturedProducts /> */}
      {/* <TrendingProducts /> */}
      {/* <TodayDeals /> */}
      {/* <Promotion /> */}
      {/* <section className="sellerCategorySec">
        <div className="container">
          <div className="row">

            <div className="col-lg-12">
              <CategoriesItems />
            </div>
          </div>
        </div>
      </section> */}
      {/* <Brands /> */}
      <Brand />
      {/* <AddingShow /> */}
      {/* <Network /> */}
      {/* <CostInfo /> */}

    </>
  );
}

export default Home;
