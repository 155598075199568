import React from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import { GiLoincloth } from "react-icons/gi";
import { ImCart } from "react-icons/im";
import { VscAccount } from "react-icons/vsc";
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdCurrencyExchange } from "react-icons/md";
import { Helmet } from "react-helmet";
import { useGetFaqsListQuery } from "../products/productSlice";

function Faq() {
  const { data, isLoading } = useGetFaqsListQuery()
  return (
    <>
      <Helmet>
        <title>FAQ | Fareenas Garments</title>
        <meta
          name="keyword"
          content="Lahanga, Choli, Saree, Salwar-Suit, Dupatta"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <Breadcrumb title="Frequently Asked Question" />
      <section className="faqSec p-30">
        <div className="container">
          <div className="row">
            {isLoading && <div className="preloaderCount">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>}

            <div className="col-lg-12 mb-3">
              <div className="faqInfo">
                <div className="pageTitle text-center">
                  <h2>Frequently Asked Question</h2>
                  <p>
                    FAREENAS is one of the most trusted and best E-commerce
                    platforms. With over 50 crore customers across 27000+ pin
                    codes, &nbsp; FAREENAS is trusted by more than 500000 sellers online
                    throughout.
                  </p>
                </div>
              </div>
            </div>

            <nav className="faqCategory">
              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                <button
                  className="nav-link faqTab active"
                  id="nav-seeds-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-seeds"
                  type="button"
                  role="tab"
                  aria-controls="nav-seeds"
                  aria-selected="true"
                >
                  <ImCart /> Order
                </button>

                <button
                  className="nav-link faqTab"
                  id="nav-protection-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-protection"
                  type="button"
                  role="tab"
                  aria-controls="nav-protection"
                  aria-selected="false"
                >
                  <VscAccount />
                  Account
                </button>

                <button
                  className="nav-link faqTab"
                  id="nav-nutrition-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-nutrition"
                  type="button"
                  role="tab"
                  aria-controls="nav-nutrition"
                  aria-selected="false"
                >
                  <RiSecurePaymentFill />
                  Payment
                </button>

                <button
                  className="nav-link faqTab"
                  id="nav-chemicals-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-chemicals"
                  type="button"
                  role="tab"
                  aria-controls="nav-chemicals"
                  aria-selected="false"
                >
                  <MdCurrencyExchange />
                  Exchange
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-seeds"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div className="faqTabText">
                  <h4 className="text-decoration-underline text-center mt-4 mb-4">
                    Order
                  </h4>
                  <div className="row">
                    <div className="col-lg-6 col-m-6">
                      <div className="faqItem">
                        <div className="accordion" id="accordionExample">
                          {/* {data && data.map((item, i) => { */}
                          {/* return */} <div /* key={item._id} */ className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button faqAccordionBtn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                {/*  {item.queries[i].question} ETG ? */}
                                <p><b>How do I shop on Fareenas?</b> </p>
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                {/*  <p>
                                  {item.queries[i].answer}
                                </p> */}
                                <p>Shopping with us is easy. At <strong> fareenas</strong>, we've designed to make your experience enjoyable.</p>
                                <p>Once you’re on our website, browse our collections by the category of your choice.</p>
                                <p>View our product all products and select the styles you want.</p>
                                <p>Choose your size OR submit your measurements on our virtual tailor module.</p>
                                <p>Click the <strong>‘Add to Cart’</strong> button.</p>
                                <p>To complete your purchase, proceed to <strong>'Checkout’</strong> To place your order, make your payment. And, you're done! Next, we deliver.</p>
                              </div>
                            </div>
                          </div>
                          {/* })} */}
                          <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                <p><b>How to place an order?</b></p>
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  1.When you find an item you wish to purchase, simply select your size (there is a size guide for every item on the product page) or submit your measurements on our virtual tailor module to get custom size.
                                </p>
                                <p>
                                  2.Now create an account by registering with us. This helps us speeds up the ordering process and allows us to keep you informed about your orders and helps in speedy return refund process if the need be.
                                </p>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-m-6">
                      <div className="faqItem">
                        <div className="accordion" id="accordionExample">
                          {/* {data && data.map((item, i) => { */}
                          {/* return */} <div /* key={item._id} */ className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button faqAccordionBtn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="true"
                                aria-controls="collapseThree"
                              >
                                {/*  {item.queries[i].question} ETG ? */}
                                <p><b>How to find a product of choice?</b> </p>
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                {/*  <p>
                                  {item.queries[i].answer}
                                </p> */}
                                <p>At <b>Fareenas,</b> you can shop in multiple product. The easiest way is to browse using the drop-down navigation menus at the top of the page, where you can find links to new products, new arrivals, all product categories etc.. Else, if you know what you are looking for, use the search box at the top center to search for a specific category of product or a particular product.</p>
                              </div>
                            </div>
                          </div>
                          {/* })} */}
                          <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="false"
                                aria-controls="collapseFour"
                              >
                                <p><b>How do I know if an item is in stock?</b></p>
                              </button>
                            </h2>
                            <div
                              id="collapseFour"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingFour"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  All items are in stock unless ‘Out of Stock’ is displayed. Please be aware that even if an item is in your shopping bag, it can still be bought by another customer until you have completed your order. If the product you want to buy is out of stock, please email
                                </p>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="nav-protection"
                role="tabpanel"
                aria-labelledby="nav-protection-tab"
              >
                <div className="faqTabText">
                  <h4 className="text-decoration-underline text-center mt-4 mb-4">
                    Account
                  </h4>
                  <div className="row">
                    <div className="col-lg-6 col-m-6">
                      <div className="faqItem">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button faqAccordionBtn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <p><b>How do I login on fareenas.com website?</b></p>
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  You can login through your google account or simply enter your email id and password. Please note that you do not have to login in order to use most of our Website. However, if you wish to save some our products to your wish list for future reference, or you want to stay in touch for being notified about our discounts and sales,you have to signup/login.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                <p><b>If I register with fareenas.com, will my personal information be kept private?</b></p>
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  Please be assured that your personal details and information are treated with utmost confidentiality and kept private. These will under no circumstance be shared with companies, organisations, or individuals outside of fareenas.com.
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingThree">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                I've forgotten my password. What should I do?
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  If you have forgotten your password, click here to change it or follow the 'FORGOT PASSWORD' instructions on the SIGN IN page
                                </p>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingFour">
                              <button
                                className="accordion-button faqAccordionBtn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="true"
                                aria-controls="collapseFour"
                              >
                                What products can I sell on ETG .com?
                              </button>
                            </h2>
                            <div
                              id="collapseFour"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingFour"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  ETG .com has more than 3000+ categories that
                                  you can opt from and sell online across 27000+
                                  pin codes of India. Some of the popular
                                  categories are Clothes, Sarees, Electronics,
                                  Shoes, Jewelry, T-Shirts, Furniture, Art,
                                  Books, Watch, Mobiles, Camera, Bed, Groceries,
                                  Beauty Products, Plants, Kitchenware, and many
                                  more. However, certain categories require
                                  prior quality approval from the internal
                                  committee before going live.
                                </p>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingFive">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                              >
                                What do I need to register to sell on ETG .com?
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingFive"
                              data-bs-parent="#accordionExample1"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  For selling product online on ETG .com, you
                                  require the following details-
                                </p>
                                <ol>
                                  <li>Details about your business</li>
                                  <li>
                                    Contact details (email id and phone number)
                                  </li>
                                  <li>
                                    Details about your tax registration- GSTIN
                                    (compulsory if your listed products are
                                    taxable) and PAN (Mandatory for Book
                                    Sellers)
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-m-6">
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item faqAccordionInfo">
                          <h2 className="accordion-header" id="headingSix">
                            <button
                              className="accordion-button faqAccordionBtn collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              <p><b> I've forgotten my password. What should I do?</b></p>
                            </button>
                          </h2>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body faqAccordionBody">
                              <p>
                                If you have forgotten your password, click here to change it or follow the 'FORGOT PASSWORD' instructions on the SIGN IN page
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="accordion-item faqAccordionInfo">
                          <h2 className="accordion-header" id="headingSeven">
                            <button
                              className="accordion-button faqAccordionBtn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="true"
                              aria-controls="collapseSeven"
                            >
                              What is FAssured?
                            </button>
                          </h2>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSeven"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body faqAccordionBody">
                              <p>
                                FAssured by ETG is a special reliability program
                                where ETG will provide additional visibility to
                                your products. It will also ensure that the
                                product goes under additional quality checks and
                                gets delivered within 2-4 days of ordering. A
                                FAssured tag guarantees more orders, faster
                                delivery, increased visibility, and higher
                                quality. In short, it is Flipkart’s seal of
                                trust, quality, and speed. By getting a FAssured
                                badge, you can get better revenue and earn trust
                                from customers.
                              </p>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="accordion-item faqAccordionInfo">
                          <h2 className="accordion-header" id="headingEight">
                            <button
                              className="accordion-button faqAccordionBtn collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Can I offer both products and services on ETG
                              .com?
                            </button>
                          </h2>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingEight"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body faqAccordionBody">
                              <p>
                                Currently, ETG only allows sellers to sell their
                                products online. However, as a third-party
                                service provider, you can offer specific
                                services to the ETG sellers to help grow their
                                business, to know more,{" "}
                                <Link to="/faq">click here.</Link>
                              </p>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="nav-nutrition"
                role="tabpanel"
                aria-labelledby="nav-nutrition-tab"
              >
                <div className="faqTabText">
                  <h4 className="text-decoration-underline text-center mt-4 mb-4">
                    Payment
                  </h4>
                  <div className="row">
                    <div className="col-lg-12 col-m-12">
                      <div className="faqItem">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button faqAccordionBtn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <p><b>What payment methods are accepted on fareenas.com?</b></p>
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  We accept debit and credit card payments (Visa, MasterCard, American Express & Maestro credit) through a secure online server. Cash on Delivery (COD) and Net Banking are other modes of payment. We also accept Paypal to receive payments from customers.
                                </p>
                                <p>
                                  If you pay by credit or debit card, you will be charged immediately as soon as your order is placed successfully.
                                </p>
                                <p>
                                  No refunds shall be applicable on COD payments.
                                </p>
                                <p>
                                  For customers overseas, all product prices displayed are exclusive of all import duties. As a recipient, you are liable for all import duties, customs and local sales taxes levied by the country you reside in, for which payment of these at the time of delivery is necessary to release your order from customs on arrival.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                <p><b> How do I check the product prices in my currency?</b></p>
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  To check product prices in your currency, select the ‘Currency:’ option of choice at the top menu of the website. In case the currency of your country is not listed, please contact our customer support at fareenas@gmail.com.
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingThree">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                What is the minimum listing quantity to sell on
                                ETG .com?
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  To sell online on ETG .com, all you require is
                                  at least 1 product to be listed. However, we
                                  recommend you to go for more than 1 product as
                                  Flipkart encompasses over 50 crore customers
                                  across 27000+ pin codes and the trust of
                                  500000 sellers.
                                </p>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingFour">
                              <button
                                className="accordion-button faqAccordionBtn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="true"
                                aria-controls="collapseFour"
                              >
                                What products can I sell on ETG .com?
                              </button>
                            </h2>
                            <div
                              id="collapseFour"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingFour"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  ETG .com has more than 3000+ categories that
                                  you can opt from and sell online across 27000+
                                  pin codes of India. Some of the popular
                                  categories are Clothes, Sarees, Electronics,
                                  Shoes, Jewelry, T-Shirts, Furniture, Art,
                                  Books, Watch, Mobiles, Camera, Bed, Groceries,
                                  Beauty Products, Plants, Kitchenware, and many
                                  more. However, certain categories require
                                  prior quality approval from the internal
                                  committee before going live.
                                </p>
                              </div>
                            </div>
                          </div> */}
                          {/*  <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingFive">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                              >
                                What do I need to register to sell on ETG .com?
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingFive"
                              data-bs-parent="#accordionExample1"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  For selling product online on ETG .com, you
                                  require the following details-
                                </p>
                                <ol>
                                  <li>Details about your business</li>
                                  <li>
                                    Contact details (email id and phone number)
                                  </li>
                                  <li>
                                    Details about your tax registration- GSTIN
                                    (compulsory if your listed products are
                                    taxable) and PAN (Mandatory for Book
                                    Sellers)
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-m-6">
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item faqAccordionInfo">
                          <h2 className="accordion-header" id="headingSix">
                            <button
                              className="accordion-button faqAccordionBtn collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              I don’t have a website; can I still sell on ETG
                              .com?
                            </button>
                          </h2>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body faqAccordionBody">
                              <p>
                                A big yes. For selling your products on ETG
                                .com, you don't require a website. You will have
                                access to ETG Seller Hub after completing your
                                registration where you can list your products
                                and start selling. However, ETG .com charges a
                                small amount of marketplace fee once your
                                product is sold out.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqAccordionInfo">
                          <h2 className="accordion-header" id="headingSeven">
                            <button
                              className="accordion-button faqAccordionBtn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="true"
                              aria-controls="collapseSeven"
                            >
                              What is FAssured?
                            </button>
                          </h2>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSeven"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body faqAccordionBody">
                              <p>
                                FAssured by ETG is a special reliability program
                                where ETG will provide additional visibility to
                                your products. It will also ensure that the
                                product goes under additional quality checks and
                                gets delivered within 2-4 days of ordering. A
                                FAssured tag guarantees more orders, faster
                                delivery, increased visibility, and higher
                                quality. In short, it is Flipkart’s seal of
                                trust, quality, and speed. By getting a FAssured
                                badge, you can get better revenue and earn trust
                                from customers.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqAccordionInfo">
                          <h2 className="accordion-header" id="headingEight">
                            <button
                              className="accordion-button faqAccordionBtn collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Can I offer both products and services on ETG
                              .com?
                            </button>
                          </h2>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingEight"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body faqAccordionBody">
                              <p>
                                Currently, ETG only allows sellers to sell their
                                products online. However, as a third-party
                                service provider, you can offer specific
                                services to the ETG sellers to help grow their
                                business, to know more,{" "}
                                <Link to="/faq">click here.</Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="nav-chemicals"
                role="tabpanel"
                aria-labelledby="nav-chemicals-tab"
              >
                <div className="faqTabText">
                  <h4 className="text-decoration-underline text-center mt-4 mb-4">
                    Exchange Products
                  </h4>
                  <div className="row">
                    <div className="col-lg-12 col-m-12">
                      <div className="faqItem">
                        <div className="accordion" id="accordionExample">
                          <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button faqAccordionBtn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                <p><b>How to exchange my order?</b></p>
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              className="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  If you have received a damaged product or the wrong item, please mail us at fareenas@gmail.com.in with your order number and a couple of pictures of the material within 48 hours of delivery. We will process the replacement of the product as soon as possible. At Fareenas, each product is quality checked passed, verified, and then shipped to the customer but we understand that manual errors can happen and hence we provide replacement of the same product free of cost.
                                </p>
                                <p><strong>Please do not return any product without our confirmation on email.</strong></p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingTwo">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                              >
                                <p><b>What is the procedure to return a product?</b></p>
                              </button>
                            </h2>
                            <div
                              id="collapseTwo"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingTwo"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p><b>Item Received in Damaged Condition / Manufacturing Defect / Wrong Item Delivered:</b></p>
                                <p>We are committed to maintaining a long-lasting relationship with our customers by providing them 100% satisfaction. If you are unsatisfied with your purchase for any reason, we will take back the product. We have a generous 7-day no-questions-asked return policy.
                                </p>
                                <p>Kindly request a return via mail at fareenas@gmail.com within 7 days from the date of delivery. Return requests will not be accepted through any other medium.
                                </p>
                                <p>The customer will be responsible for shipping the product to our warehouse in Surat. Fareenas will reimburse (100/- rupees) reward points for the return shipment.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingThree">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                <p><b>Can I change or amend my order once it has been placed?</b></p>
                              </button>
                            </h2>
                            <div
                              id="collapseThree"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingThree"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  You can cancel you order within 24 hours of placing it and if it hasn’t been dispatched already. If you'd like to purchase another piece instead of what you already have, that will be a fresh purchase and transaction. If you've ordered the wrong size, we can exchange or alter it for you if possible for us. Only, the shipping charge will be borne by you.
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingFour">
                              <button
                                className="accordion-button faqAccordionBtn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFour"
                                aria-expanded="true"
                                aria-controls="collapseFour"
                              >
                                What products can I sell on ETG .com?
                              </button>
                            </h2>
                            <div
                              id="collapseFour"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingFour"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  ETG .com has more than 3000+ categories that
                                  you can opt from and sell online across 27000+
                                  pin codes of India. Some of the popular
                                  categories are Clothes, Sarees, Electronics,
                                  Shoes, Jewelry, T-Shirts, Furniture, Art,
                                  Books, Watch, Mobiles, Camera, Bed, Groceries,
                                  Beauty Products, Plants, Kitchenware, and many
                                  more. However, certain categories require
                                  prior quality approval from the internal
                                  committee before going live.
                                </p>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="accordion-item faqAccordionInfo">
                            <h2 className="accordion-header" id="headingFive">
                              <button
                                className="accordion-button faqAccordionBtn collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseFive"
                                aria-expanded="false"
                                aria-controls="collapseFive"
                              >
                                What do I need to register to sell on ETG .com?
                              </button>
                            </h2>
                            <div
                              id="collapseFive"
                              className="accordion-collapse collapse"
                              aria-labelledby="headingFive"
                              data-bs-parent="#accordionExample1"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>
                                  For selling product online on ETG .com, you
                                  require the following details-
                                </p>
                                <ol>
                                  <li>Details about your business</li>
                                  <li>
                                    Contact details (email id and phone number)
                                  </li>
                                  <li>
                                    Details about your tax registration- GSTIN
                                    (compulsory if your listed products are
                                    taxable) and PAN (Mandatory for Book
                                    Sellers)
                                  </li>
                                </ol>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-6 col-m-6">
                      <div className="accordion" id="accordionExample1">
                        <div className="accordion-item faqAccordionInfo">
                          <h2 className="accordion-header" id="headingSix">
                            <button
                              className="accordion-button faqAccordionBtn collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSix"
                              aria-expanded="false"
                              aria-controls="collapseSix"
                            >
                              I don’t have a website; can I still sell on ETG
                              .com?
                            </button>
                          </h2>
                          <div
                            id="collapseSix"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body faqAccordionBody">
                              <p>
                                A big yes. For selling your products on ETG
                                .com, you don't require a website. You will have
                                access to ETG Seller Hub after completing your
                                registration where you can list your products
                                and start selling. However, ETG .com charges a
                                small amount of marketplace fee once your
                                product is sold out.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqAccordionInfo">
                          <h2 className="accordion-header" id="headingSeven">
                            <button
                              className="accordion-button faqAccordionBtn"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseSeven"
                              aria-expanded="true"
                              aria-controls="collapseSeven"
                            >
                              What is FAssured?
                            </button>
                          </h2>
                          <div
                            id="collapseSeven"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingSeven"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body faqAccordionBody">
                              <p>
                                FAssured by ETG is a special reliability program
                                where ETG will provide additional visibility to
                                your products. It will also ensure that the
                                product goes under additional quality checks and
                                gets delivered within 2-4 days of ordering. A
                                FAssured tag guarantees more orders, faster
                                delivery, increased visibility, and higher
                                quality. In short, it is Flipkart’s seal of
                                trust, quality, and speed. By getting a FAssured
                                badge, you can get better revenue and earn trust
                                from customers.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="accordion-item faqAccordionInfo">
                          <h2 className="accordion-header" id="headingEight">
                            <button
                              className="accordion-button faqAccordionBtn collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseEight"
                              aria-expanded="false"
                              aria-controls="collapseEight"
                            >
                              Can I offer both products and services on ETG
                              .com?
                            </button>
                          </h2>
                          <div
                            id="collapseEight"
                            className="accordion-collapse collapse"
                            aria-labelledby="headingEight"
                            data-bs-parent="#accordionExample1"
                          >
                            <div className="accordion-body faqAccordionBody">
                              <p>
                                Currently, ETG only allows sellers to sell their
                                products online. However, as a third-party
                                service provider, you can offer specific
                                services to the ETG sellers to help grow their
                                business, to know more,{" "}
                                <Link to="/faq">click here.</Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
