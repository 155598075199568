import { Link } from "react-router-dom"
import UnderProductItem from "./UnderProductItem"

function UnderProduct() {
    return (
        <>
            <section className="under-productsSection">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="fishermanHeader d-flex justify-content-between align-items-end mb-4">
                                <div className="fisherman-content">
                                    <span>Under Rs.199 Store</span>
                                    <h3>Stay meativated on a budget</h3>
                                </div>
                                <div className="fisherman-btn">
                                    <Link to="/products" className="optional-btn">
                                        View More
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <UnderProductItem />
                    </div>
                </div>
            </section>
        </>
    )
}
export default UnderProduct