import React from 'react'
import vid1 from "../../../../../assets/img/fareenasProducts/fareenasVideo/cero.mp4"
import vid2 from "../../../../../assets/img/fareenasProducts/fareenasVideo/haldiFunction.mp4"
import vid3 from "../../../../../assets/img/fareenasProducts/fareenasVideo/occasion.mp4"
import vid4 from "../../../../../assets/img/fareenasProducts/fareenasVideo/salwarSuit.mp4"
import vid5 from "../../../../../assets/img/fareenasProducts/fareenasVideo/haldiFunction.mp4"
import vid6 from "../../../../../assets/img/fareenasProducts/fareenasVideo/cero.mp4"
const videos = [
    { id: "1", vidUrl: vid1, tittle: " Ceremony Lahanga" },
    { id: "2", vidUrl: vid2, tittle: "-------" },
    { id: "3", vidUrl: vid3, tittle: "Sharara Suits" },
    { id: "4", vidUrl: vid4, tittle: "Salwar" },
    { id: "5", vidUrl: vid5, tittle: "-------" },
    { id: "6", vidUrl: vid6, tittle: "Lahang's" },
]
function VideosItem() {
    return (
        <div className='row'>
            {videos.map((item) => {
                return <div className='col-lg-2 col-md-4 col-sm-6 '>
                    <div className='' >
                        <div style={{ border: "solid 1px #d79d2e", padding: "13px 0 0 0", borderRadius: "4px" }}>
                            <video
                                controls
                                loop
                                autoplay="true"
                                muted="true"
                                preload="metadata"
                                src={item?.vidUrl}
                                width={100 + "%"}
                                height="250px"
                                poster="benefits-of-coding.jpg"
                            ></video>
                        </div>
                        <div className='text-center'>{item?.tittle}</div>
                    </div>
                </div>
            })}

        </div>
    )
}

export default VideosItem
