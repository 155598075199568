import React, { useEffect, useState } from "react";
import fertilizer1 from "../../../../assets/img/products/1.jpg";
import Rating from "../../../../shared/rating/Rating";

import { FiSearch } from "react-icons/fi";
import { GrAdd } from "react-icons/gr";
import { BsFillCartFill } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";

// You need to import the CSS only once
// import "react-awesome-lightbox/build/style.css";

import "./ProductItem.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ImgesData } from "../../proImgs/ImgesData";
import axios from "axios";
import { setCartLists, useOfflineAddPostMutation, useSetCartMutation } from "../../../products/productSlice";
import { useDispatch, useSelector } from "react-redux";

import { featuredDB } from "../../../../rki-data/category";


import tp1 from '../../../../assets/img/leatzmeat/product/tp-1.jpg'
import tp2 from '../../../../assets/img/leatzmeat/product/tp-2.jpg'
import tp3 from '../../../../assets/img/leatzmeat/product/tp-3.jpg'
import tp4 from '../../../../assets/img/leatzmeat/product/tp-4.jpg'
import tp5 from '../../../../assets/img/leatzmeat/product/tp-5.jpg'
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

const productData = [
  { _id: "2", proUrl: tp2, title: "Chicken Curry Cut - Small Pieces" },
  { _id: "3", proUrl: tp3, title: "Afghani Murgh Seekh Kebab" },
  { _id: "4", proUrl: tp4, title: "Chicken Breast Boneless (Large Pack)" },
  { _id: "1", proUrl: tp1, title: "Chunky Shawarma Chicken Spread" },
  { _id: "5", proUrl: tp5, title: "Afghani Murgh Seekh Kebab" },
  { _id: "6", proUrl: tp1, title: "Chicken Breast Boneless" },
  // { _id: "7", proUrl: tp2, title: "Chicken Boneless - Mini Bites" },
  // { _id: "8", proUrl: tp5, title: "Chicken Boneless - Mini Bites" },
]

function ProductItem({ loadMore, setTotalProductLength, latestData, setLatestData, handleShow }) {
  const userid = window.localStorage.getItem("user_id");
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const [isLoading, SetIsloading] = useState(true)

  const getData = async (id) => {
    console.log(id);
    try {
      const res = await axios.get(`https://onlineparttimejobs.in/api/product/page/${id}&${18}`, { withCredentials: true })
      // console.log('res---', res.data.title);
      setData(res.data)
      SetIsloading(false)
    } catch (error) {
      setError(true)
      SetIsloading(false)
    }
  }
  const curr = window.localStorage.getItem('currencySym')
  let currencySymbol = curr
  if (currencySymbol === 'undefined') {
    currencySymbol = 'ZK'
  }

  const params = useParams();
  useEffect(() => {
    if (!data) {
      getData(0)
    }

    if (params.val) {
      const filteredData = data?.filter((currentElm) => {
        const currentName = currentElm?.name?.toLowerCase();
        const currentVal = params.val.toLowerCase();

        if (currentName) {
          if (
            currentName.includes(currentVal) ||
            currentElm.brand === currentVal
          ) {
            return currentElm;
          }
        }

      });
      // setLatestData(filteredData);
      // setTotalProductLength(filteredData)
    } else {
      // setLatestData(data);
      // setTotalProductLength(data)
    }
  }, []);

  const [addToCart, { data: datacart, isLoading: isAddCartLoading, isSuccess: isAddToCartSuccess, isError: isAddToCartError }] = useSetCartMutation();
  const isLogin = window.localStorage.getItem("isLogin");
  const [postOffline, { data: resData, isSuccess, isError: offErr, isLoading: isloadPost }] = useOfflineAddPostMutation()
  const navigate = useNavigate()


  const { updatedProducts: products } = useSelector((state) => {
    return state.productList
  })
  const BuyNowPro = (item) => {
    if (isLogin === 'false') {
      postOffline({
        product_count: 1,
        product_variant: item?.variations[0]._id,
        deliveryType: 'HOME DELIVERY',
        seller_id: '64269f0df127906d53878d3d',
        sku: item?.variations[0].sku,
        product_id: item?._id,
        products: products ? products : []
      })

    } else {
      const payload = {
        product_count: 1,
        product_variant: item?.variations[0]._id,
        deliveryType: 'HOME DELIVERY',
        seller_id: '64269f0df127906d53878d3d',
        sku: item?.variations[0].sku,
        product_id: item?._id,
        userid,

      }
      addToCart(payload)

    }

  }

  useEffect(() => {
    if (isSuccess) {
      navigate('/cart')
    }
  }, [isSuccess])

  const dispacher = useDispatch()
  useEffect(() => {
    if (isSuccess) {
      dispacher(setCartLists(resData.cart.products))
    }
  }, [isSuccess, offErr])


  const { t } = useTranslation()

  return (
    <>
      {isLoading ? (
        <div className="loaderIcon">
          <BiLoaderAlt />
        </div>
      ) : null}
      {/* <CollectionFilter latestData={latestData} /> */}
      {data?.length === 0 && (<h6 className="text-center mb-5">No Found Data</h6>)}
      {data?.slice(0, loadMore).map((item, i) => {
        return (
          <div className="col-lg-3 col-md-6 col-sm-12" key={item._id}>
            <div className="featuredInfo sevi">
              <div className="featuredFigure">
                {isloadPost && <div className="preloaderCount">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>}
                <div className="featuredImg" style={{ display: "flex", justifyContent: "center", }}>
                  {/* {item.mainimage_url ? <Link to={`/product/${item._id}`}>
                    <img src={item.mainimage_url} alt="Product" />
                  </Link> : <Link to={`/product/${item._id}`}>
                    <img src={fertilizer4} alt="Product" />
                  </Link>} */}
                  <Link to={`/product/${item?.uid}`}>
                    {item?.variations[0]?.mainImage_url?.url ? <img src={item?.variations[0]?.mainImage_url?.url} alt="Product" className="imgProduct" /> : <img src={item?.proUrl} alt="Product" />}
                  </Link>
                  <div className="quickView">
                    <ul>
                      <li className="viewProduct">
                        <button
                          className="quick_view_btn"
                          onClick={(e) => {
                            handleShow(item?.uid)
                          }}
                        >
                          <FiSearch />
                        </button>
                      </li>
                      <li className="addProduct">
                        <Link to="/products">
                          <GrAdd />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="hotList">
                  <li>
                    <Link to="/products">hot</Link>
                  </li>
                  <li>
                    {/* <Link to="products">- {item?.variations[0].discount}</Link> */}
                  </li>
                </ul>
              </div>
              <div className="featuredContent">
                <h6>category</h6>
                <h5>
                  <Link to={`/product/${item?.uid}`}>{item?.name}</Link>
                </h5>
                {/* <Rating /> */}

                <div className="price-setting">
                  {/* <span className="text_2">4 Pieces</span> */}
                  {/* <div className="SmallProductCard_price_wrapper__fR0cD"><span className="title_4 price-color">{item?.variations[0]?.mrp ? item?.variations[0]?.mrp : ''}</span>&nbsp;</div>
                  <div className="SmallProductCard_delivery_wrapper__5doc8"><span className="text_2">Tomorrow <b>8 AM - 11 AM</b></span></div> */}
                </div>
                <div className="rateDigit">
                  <span className="cross">{item?.variations[0]?.prices?.country_id?.currency_id?.symbol}{item?.variations[0]?.prices?.mrp}</span>
                  <span className="currentPrice">{item?.variations[0]?.prices?.country_id?.currency_id?.symbol}{item?.variations[0]?.prices?.sale_rate}</span>
                </div>
                {/* <div className="rateDigit">
                  {item.variations && <span className="currentPrice">Variant: </span>}
                  {
                    item.variations && item?.variations.map((item) => {
                      return <span className="currentPrice"> {item.weight},</span>
                    })
                  }
                </div> */}
                <div className="buyNowInfo">

                  <Link to={`/product/${item?.uid}`} className="btn btn-danger addCart">View Detail</Link>
                  <Link to="#" onClick={() => { BuyNowPro(item) }} className="btn btn-primary buyNow">
                    <BsFillCartFill /> Buy Now
                  </Link>
                </div>
                <div className="productDesc">
                  <p>{item.meta_description}</p>
                </div>
                <div className="featuredOption">
                  <select defaultValue={"DEFAULT"}>
                    <option value="DEFAULT">Select Option</option>
                    <option value="one">One</option>
                    <option value="two">Two</option>
                  </select>
                </div>
              </div>
            </div>

          </div>
        );
      })}

      {error && (
        <div className="alertMsg mb-4" role="alert">
          <h4 style={{ color: "red" }}>Server Error</h4>
        </div>
      )}

      <nav aria-label="Page navigation example text-cneter">
        <ul className="pagination" style={{ justifyContent: "center" }}>

          {/* <li className="page-item" onClick={() => getData(0)}><button className="page-link">1</button></li>
          <li className="page-item" onClick={() => getData(1)}><button className="page-link">2</button></li>
          <li className="page-item" onClick={() => getData(2)}><button className="page-link">3</button></li>
          <li className="page-item" onClick={() => getData(3)}><button className="page-link">4</button></li>
          <li className="page-item" onClick={() => getData(4)}><button className="page-link">5</button></li>
          <li className="page-item" onClick={() => getData(5)}><button className="page-link">6</button></li>
          <li className="page-item" onClick={() => getData(6)}><button className="page-link">7</button></li>
          <li className="page-item" onClick={() => getData(7)}><button className="page-link">8</button></li> */}
          <Button style={{ backgroundColor: "#d79d2e", textTransform: "uppercase", fontSize: "13px" }}>View More</Button>

        </ul>
      </nav>

    </>
  );
}

export default ProductItem;
