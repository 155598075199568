

import "./brand.css"
import info1 from '../../../assets/img/leatzmeat/proQuality/pq1.png'
import info2 from '../../../assets/img/leatzmeat/proQuality/pq2.png'
import info3 from '../../../assets/img/leatzmeat/proQuality/pq3.png'
import info4 from '../../../assets/img/leatzmeat/proQuality/pq5.png'
import info5 from '../../../assets/img/leatzmeat/proQuality/pq6.png'
function Brand() {
    return (
        <>
            <section className="information py-4 ">
                <div className="container">
                    <div className="row">
                        <div className='d-flex justify-content-between deluxRow'>
                            <div className="delux">
                                <div className="food-quality">
                                    <img src={info1} alt="" width={140} />
                                </div>
                            </div>

                            <div className="delux">
                                <div className="food-quality">
                                    <img src={info2} alt="" width={140} />
                                </div>
                            </div>
                            <div className="delux"><div className="food-quality">
                                <img src={info3} alt="" width={140} />
                            </div>
                            </div>
                            <div className="delux">
                                <div className="food-quality">
                                    <img src={info4} alt="" width={140} />
                                </div>
                            </div>
                            <div className="delux">
                                <div className="food-quality">
                                    <img src={info5} alt="" width={140} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Brand