import React from "react";
import { Link } from "react-router-dom";
import blog1 from "../../../assets/img/fareenasProducts/blog/sarees.webp";
import blog2 from "../../../assets/img/product-detail/fertilizer-application-bg1.jpg";
// import blog3 from "../../../assets/img/fertilizer-application3.jpg"

import { MdOutlineAdminPanelSettings, MdPhoneInTalk } from "react-icons/md";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { useGetBlogsQuery } from "../../products/productSlice";
function BlogItem() {
  const { data, isLoading } = useGetBlogsQuery()
  return (
    <>
      <section className="blogItemSec">
        <div className="row">

          {isLoading && <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}

          {data && data.map((item) => {
            console.log('item.....', item);
            return <div key={item._id} className="col-lg-6">
              <div className="blogItemInfo">
                <div className="blogItemFigure">
                  {/* {item?.banner ? (<img src={item?.banner} alt="Blog" />) : (<img src={blog1} alt="Blog" />)} */}
                  {/* <img src={item?.banner} alt="Blog" /> */}
                  <img src={blog1} alt="Blog" />
                  <div className="postDate">
                    <span className="date">25 <sup>Oct</sup></span>
                    <span className="month">posted</span>
                  </div>
                </div>
                <div className="blogItemContent">
                  <div className="author">
                    <ul>
                      <li>
                        <Link to={`/blog-detail/${item._id}`}>
                          <MdOutlineAdminPanelSettings />
                          By Ismail
                        </Link>
                      </li>
                      <li>
                        <Link to={`/blog-detail/${item._id}`}>
                          {" "}
                          <MdPhoneInTalk /> Consulting
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="title">
                    <h4>
                      <Link to={`/blog-detail/${item._id}`}>{item.title}</Link>
                    </h4>
                    <p>
                      {item.short_description}
                    </p>
                  </div>
                  <div className="readMore">
                    <Link to={`/blog-detail/${item._id}`}>
                      Read More <HiOutlineArrowNarrowRight />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          })}



        </div>
      </section>
    </>
  );
}

export default BlogItem;
