import React from 'react'
import VideosItem from './videosItems/VideosItem'

function ProductVideosWrapp() {
    return (
        <section className="productsSection">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="fishermanHeader d-flex justify-content-center align-items-end mb-4">
                            <div className="fisherman-content">
                                {/* <span>{t('Our Products')}</span> */}
                                {/* <h3>{t('TRENDING PRODUCTS')}</h3> */}
                                <span>{('TRENDING PRODUCTS')}</span>
                            </div>
                            {/* <div className="fisherman-btn">
                                <Link to="/products" className="optional-btn">
                                    {t('View More')}
                                </Link>
                            </div> */}
                        </div>
                    </div>


                </div>
                <VideosItem />
            </div>
        </section>
    )
}

export default ProductVideosWrapp
